exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-datadive-js": () => import("./../../../src/pages/datadive.js" /* webpackChunkName: "component---src-pages-datadive-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-selected-services-js": () => import("./../../../src/pages/SelectedServices.js" /* webpackChunkName: "component---src-pages-selected-services-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-whoweare-js": () => import("./../../../src/pages/whoweare.js" /* webpackChunkName: "component---src-pages-whoweare-js" */)
}

